import React from "react";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";

const DownloadPDF = ({ elementId, studentData, logo }) => {
  const handleDownload = () => {
    const input = document.getElementById(elementId);

    if (!input) {
      console.error(`Element with id ${elementId} not found`);
      return;
    }

    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF();

      if (logo) {
        // If logo is provided, add it
        const image = new Image();
        image.src = logo;
        image.onload = () => {
          // Adjust the position and size of the logo as needed
          pdf.addImage(image, "PNG", 10, 10, 30, 30);

          // Add additional text (e.g., school name)
          pdf.setFontSize(16);
          pdf.text("Jesus Children Secondary School", 50, 20);

          // Add the captured content
          pdf.addImage(imgData, "PNG", 10, 50, 190, 0);

          // Optionally add a footer
          pdf.setFontSize(12);
          pdf.text("Generated by JCS", 10, 290);

          // Save the PDF
          pdf.save(`${studentData?.firstName || "Student"}_Result.pdf`);
        };
      } else {
        // If no logo is provided, just add the captured content
        pdf.addImage(imgData, "PNG", 10, 10, 190, 0);
        pdf.save(`${studentData?.firstName || "Student"}_Result.pdf`);
      }
    });
  };

  return <button onClick={handleDownload}>Download Result</button>;
};

export default DownloadPDF;
